import React, { useState, useEffect } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SocialsList from "shared/SocialsList/SocialsList";
import { ChevronDownIcon } from "@heroicons/react/solid";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import SearchDropdown from "shared/Header/SearchDropdown";
import axios from "axios";
import { Link } from "react-router-dom";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}
export interface User_I {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  isStripeConnectedAccount?: boolean;
  loggedIn: boolean;
  stripeConnectedAccountId?: string;
  token?: string;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION_DEMO,
  onClickClose,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState<User_I | null>(null);

  useEffect(() => {
    axios
      .get("/api/loggedIn")
      .then((res) => {
        console.log(res.data);
        if (res.data.loggedIn) {
          console.log(res.data);
          setIsLoggedIn(true);
          setUser(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function logout() {
    axios.delete("/api/logout").then(() => {
      setIsLoggedIn(false);
    });
  }
  const stripeConnect = async () => {
    try {
      setLoading(true);
      const email = user.email;
      const { data } = await axios.post("/api/stripe/connect", { email });
      const { url } = data;

      if (url) {
        window.location.href = url;
      } else {
        console.log("No URL returned from Stripe.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error connecting to Stripe:", error);
      setLoading(false);
    }
  };
  const _renderMenuChild = (item: NavItemType) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <NavLink
              exact
              strict
              to={{
                pathname: i.href || undefined,
              }}
              className="flex px-4 py-2.5 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-[2px]"
              activeClassName="text-secondary"
            >
              <span
                className={!i.children ? "block w-full" : ""}
                onClick={onClickClose}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="block flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex justify-end flex-grow"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-neutral-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && (
              <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure
        key={item._id}
        as="li"
        className="text-neutral-900 dark:text-white"
      >
        <NavLink
          exact
          strict
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
          to={{
            pathname: item.href || undefined,
          }}
          activeClassName="text-secondary"
        >
          <span
            className={!item.children ? "block w-full" : ""}
            onClick={onClickClose}
          >
            {item.name}
          </span>
          {item.children && (
            <span
              className="block flex-grow"
              onClick={(e) => e.preventDefault()}
            >
              <Disclosure.Button
                as="span"
                className="flex justify-end flex-grow"
              >
                <ChevronDownIcon
                  className="ml-2 h-4 w-4 text-neutral-500"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
          )}
        </NavLink>
        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  return (
    <>
      {" "}
      {loading && (
        <div className="fixed inset-0 bg-gray-100 bg-opacity-80 flex justify-center items-center z-50">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid border-gray-300"></div>
        </div>
      )}{" "}
      <div className="overflow-y-auto w-full max-w-sm h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
        <div className="py-6 px-5">
          <Logo />
          <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
            <span>Enjoy your community by renting from those within!</span>

            <div className="flex justify-between items-center mt-4">
              <SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300" />
              <span className="block">
                <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" />
              </span>
            </div>
          </div>
          <span className="absolute right-2 top-2 p-1">
            <ButtonClose onClick={onClickClose} />
          </span>
        </div>
        {/* <ul className="flex flex-col py-6 px-2 space-y-1">
        {data.map(_renderItem)}
      </ul> */}
        <div className="flex items-center justify-between py-6 px-5 space-x-4">
          {/* <a href="/#" target="_blank" rel="noopener noreferrer">
          <ButtonPrimary>Get ZVC </ButtonPrimary>
        </a> */}
          {/* <div className="hidden items-center xl:flex space-x-1"> */}
          {/* <SwitchDarkMode />
            <SearchDropdown /> */}
          {/* <div className="px-1" /> */}
          {!isLoggedIn ? (
            <ButtonPrimary href="/login">Login</ButtonPrimary>
          ) : (
            <>
              <div className="flex-col items-center space-y-4">
                <div className="flex items-center justify-between   space-x-4 ">
                  <Link to={{ pathname: "/add-listing-1", state: user }}>
                    <ButtonPrimary>My Experiences</ButtonPrimary>
                  </Link>
                  <Link to={{ pathname: "/" }}>
                    <ButtonPrimary onClick={logout}>Logout</ButtonPrimary>
                  </Link>
                </div>{" "}
                <div className="flex items-center justify-between space-x-2">
                  <Link to={{ pathname: "/" }}>
                    <ButtonPrimary onClick={stripeConnect}>
                      Stripe Account
                    </ButtonPrimary>
                  </Link>
                  <p>
                    {user?.stripeConnectedAccountId &&
                      "(" + user?.stripeConnectedAccountId + ")"}
                  </p>
                </div>{" "}
              </div>
            </>
          )}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default NavMobile;
