import React, { FC, useEffect, useState, useRef } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import axios from "axios";
import { Link } from "react-router-dom";
import { UserCircleIcon } from "@heroicons/react/solid";
import { User_I } from "shared/Navigation/NavMobile";

export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [userData, setUserData] = useState<User_I | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const stripeConnect = async () => {
    try {
      setLoading(true);
      const email = userData.email;
      console.log("🚀 ~ stripeConnect ~ email:", email);
      const { data } = await axios.post("/api/stripe/connect", { email });
      const { url } = data;

      if (url) {
        window.location.href = url;
      } else {
        console.log("No URL returned from Stripe.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error connecting to Stripe:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/loggedIn")
      .then((res) => {
        console.log(res.data);
        setUserData(res.data);

        if (res.data.loggedIn) {
          setIsLoggedIn(true);
          setUser(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const handleLogout = () => {
    axios.delete("/api/logout").then(() => {
      setIsLoggedIn(false);
      setDropdownOpen(false);
    });
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Handle click outside of dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {" "}
      {loading && (
        <div className="fixed inset-0 bg-gray-100 bg-opacity-80 flex justify-center items-center z-50">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid border-gray-300"></div>
        </div>
      )}{" "}
      <div
        className={`nc-MainNav1 relative z-10 ${
          isTop ? "onTop " : "notOnTop backdrop-filter"
        }`}
      >
        <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
          <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
            <Logo />
            <Navigation />
          </div>
          <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
            <div className="hidden items-center xl:flex space-x-1">
              <SwitchDarkMode />
              <SearchDropdown />
              <div className="px-1" />
              {!isLoggedIn ? (
                <ButtonPrimary href="/login">Login</ButtonPrimary>
              ) : (
                <>
                  <Link to={{ pathname: "/add-listing-1", state: user }}>
                    <ButtonPrimary>My Experiences</ButtonPrimary>
                  </Link>

                  <div ref={dropdownRef} className="relative">
                    <button
                      onClick={toggleDropdown}
                      className="focus:outline-none"
                    >
                      <UserCircleIcon className="h-8 w-8 text-gray-600 dark:text-gray-300" />
                    </button>

                    {dropdownOpen && (
                      <div className="absolute right-0 mt-2 w-58 bg-white dark:bg-gray-800 shadow-lg rounded-md z-50">
                        <ul className="py-1 text-gray-700 dark:text-gray-200">
                          <li
                            onClick={stripeConnect}
                            className="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                          >
                            Stripe Account{" "}
                            {userData?.stripeConnectedAccountId &&
                              "(" + userData?.stripeConnectedAccountId + ")"}
                          </li>

                          {/* <li className="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-600">
                      <Link to="/add-listing-1">My Experiences</Link>
                    </li> */}
                          <li
                            onClick={handleLogout}
                            className="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                          >
                            Logout
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="flex items-center xl:hidden">
              <SwitchDarkMode />
              <div className="px-1" />
              <MenuBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainNav1;
